body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#c1be1c;
}

.App {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gridiv {
min-width: 300px;
min-height: 300px;
max-width: 800px;
text-align: center;
display: inline-block;
cursor: pointer;
}

div.gridiv > img {
width: 100%;
height: auto;
}

.stage {
  position:absolute;
  border-radius: 50%;
  background-color: #c1be1c;
  opacity: 0.9;
  border:#ffffff 3px solid;
  color:#ffff;
  font-size: 20pt;
  text-align: center;
  font-weight: bold;
  line-height: 60px;
  width:60px;
  height:60px;
  z-index: 999;
  margin: 15px 0px 0px 15px;
  padding:0px;
}

.imgPreload {
  width: 0px;
  height: 0px;
  visibility: hidden;
}